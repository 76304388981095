<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Phiếu linh kiện chờ xử lý</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-checkbox
          v-model="selectedStatus"
          class="mt-1"
          dense
          hide-details
          v-for="item in status"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Hành động</div>
        <v-checkbox
          v-model="selectedAction"
          v-for="item in ACTION_STATUS"
          :label="item.label"
          :key="item.value"
          :value="item.value"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Người phụ trách</div>
        <input-autocomplete
          class="tp-filter-autocomplete"
          :items="employees"
          item-text="name"
          item-value="id"
          multiple
          v-model="employeesFilter"
          placeholder="Chọn nhân viên"
          :updateValue="updateEmployees"
        ></input-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ACTION_STATUS } from "@/modules/NotSoldWarehouse/utils/static-data";
import InputAutocomplete from "../../../../components/common/InputAutocomplete";

export default {
  components: {
    InputAutocomplete
  },
  props: {
    actionFilter: {
      type: Array
    },
    employeesFilter: {
      type: Array
    },
    statusFilter: {
      type: [Number, Array]
    }
  },
  data() {
    return {
      ACTION_STATUS: ACTION_STATUS,
      status: [
        {
          label: "Chờ tiếp nhận",
          value: 1
        },
        {
          label: "Đã tiếp nhận",
          value: 2
        },
        {
          label: "Đã xử lý",
          value: 3
        }
      ]
    };
  },
  computed: {
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    selectedAction: {
      get() {
        return this.actionFilter;
      },
      set(val) {
        this.$emit("updateActionFilter", val);
      }
    },

    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    }
  },
  created() {
    this.$store.dispatch("EMPLOYEE/getAllEmployees", "ENGINEERING");
  },
  methods: {
    updateEmployees(val) {
      this.$emit("updateEmployeesFilter", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
